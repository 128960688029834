import {ApiRes} from '@type/api'
import {apiGet} from '@api/api'

export interface ITrending {
    id: string
    title?: string
    thumbnail?: string
    content?: string
    link?: string
    type?: string
    language?: string
    created_at: number
    priority?: number
    is_active?: boolean
    owner?: {
        name?: string
        thumbnail?: string
    }
}

export const apiGetTrending = async (
    current_page: number = 1,
    page_size?: number,
    language?: string,
): Promise<ApiRes<ITrending[]>> => {
    return apiGet(`hub/trending?current_page=${current_page}&page_size=${page_size}`, {cookies: {language: language}})
}
